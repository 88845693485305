function handleToggleModalFn() {
  document.querySelector('#modalOpener').addEventListener('click', function () {
    document.querySelector('#modal').style.display = 'initial';
    document.querySelector('#modal').classList.add('is-open');
    document.querySelector('main').classList.add('overlay');
    var iframe = document.querySelector('iframe');
    iframe.contentWindow.postMessage(
      '{"event":"command","func":"playVideo","args":""}',
      '*'
    );
  });

  document.querySelector('#modalCloser').addEventListener('click', function () {
    document.querySelector('#modal').classList.remove('is-open');
    document.querySelector('main').classList.remove('overlay');
    setTimeout(function () {
      document.querySelector('#modal').style.display = 'none';
    }, 200);
    // stop yt video
    var iframe = document.querySelector('iframe');
    iframe.contentWindow.postMessage(
      '{"event":"command","func":"pauseVideo","args":""}',
      '*'
    );
  });
}
document.addEventListener('DOMContentLoaded', function () {
  handleToggleModalFn();
  new Accordion('.accordion-container');
});
